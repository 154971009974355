import React from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css';

const ButtonWatch = styled.button`
  /* Clear default button styles */
  background-color: transparent;
  color: transparent;
  border: transparent;
  border-radius: 3px;
  -webkit-appearance: none;
  padding: 0px;

  transition: 100ms ease, color 100ms ease; 

  .IconBox {
    width: 45px;
    padding: 6px 0px;
    border-radius: 3px;

    transition: 100ms ease, color 100ms ease; 
  }
  i {
    color: #0033a1;

    transition: 100ms ease, color 100ms ease; 
  }
  p {
    display: none;
    font-size: 10px;
    vertical-align: middle;

    transition: 100ms ease, color 100ms ease; 
  }
  /* 💅 HOVER STYLES */
  :hover .IconBox {
    color: #fff;
    background-color: #26c1fb;
    border-color: #26c1fb;

    transition: 100ms ease, color 100ms ease; 
  }
  :hover i {
    color: #fff;

    transition: 100ms ease, color 100ms ease; 
  }
  :hover p {
    color: #fff;
    display: block;

    transition: 100ms ease, color 100ms ease; 
  }
`;
const AccordionButton = () => {
  return (
      <ButtonWatch>
        <div className="IconBox fade-in">
        <i class="far fa-bookmark"></i>
          <p>Watch</p>
        </div>
      </ButtonWatch>
  )
}
export default AccordionButton;